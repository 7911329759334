// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:35:24+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            a3da13e8b291b2d97ea57a86ff95bb08
//    Signature:      eyJpdiI6Im93VThpTWxYUEc4d1hLRVNSK2VDcnc9PSIsInZhbHVlIjoibDRuRjJUV2VxZE5oam1EZVJHOHR6M2tYOXpnbHNvUlU1THNyXC82QWdzdGd6NU4wcDdVWGFoUytNWCtHTkVGZGtaUjFhN1cxWkVuSUxEVjZoeHNFcVY1VUFWM25KTjh6MEtUSnpTTGFRdVowRGRqUUwzVmoyMTBodGxJbUkzNmhsa0p4ZlcyZTBQZGJhVmpTNFVtcUVCVVZ2dHNsSEk5TGZMKyt5UUdNTkE1aHJhMTU2ZHhcLzdiclRVbHRGaU9YK0FQR1wvTjlieElPN2w3bElGOXRxZDJHUUVLV01LRktZVmRGWGI5Misrd2puTUUzRlwvdEo0ejlvRStTYUdicGlvZHYxalRUbWdZODBVUnJNSUZHYVRxZUJUemd0dFNmd1lyUldMdGJkSlpCUXQrNXArUStFSTUzNDNDOEkzSFdrWlVWRFwvdmNcL2tRMElKc29Yd1gxc2F2QXc4NHJhdExBN3czQ3NTVGFsTVdFSVJsWlRaR0l0bHJSR2thYk1MQWhKak5pN3JMXC9Fd3hrQ0d3ejBqSkUyc0JRcmc9PSIsIm1hYyI6IjI4NTBjZTg0YjJkNWQ0ODFkMDJkY2Y2MWRiYWViMTJiYWVkMGFlZjBkZjM0ZmM4MThiYzE2NmQwZDUyN2M3OTgifQ==
document.addEventListener('shopify:block:select', function(event) {
  const blockSelectedIsSlide = event.target.classList.contains('slideshow__slide');
  if (!blockSelectedIsSlide) return;

  const parentSlideshowComponent = event.target.closest('slideshow-component');
  parentSlideshowComponent.pause();

  setTimeout(function() {
    parentSlideshowComponent.slider.scrollTo({
      left: event.target.offsetLeft
    });
  }, 200);
});

document.addEventListener('shopify:block:deselect', function(event) {
  const blockDeselectedIsSlide = event.target.classList.contains('slideshow__slide');
  if (!blockDeselectedIsSlide) return;
  const parentSlideshowComponent = event.target.closest('slideshow-component');
  if (parentSlideshowComponent.autoplayButtonIsSetToPlay) parentSlideshowComponent.play();
});
